<template>
	<v-container fluid class="pa-0">
		<ResetPassword v-if="step == 'ResetPassword'" @nextStep="nextStep" :userId="userId" />
	</v-container>
</template>

<script>
import ResetPassword from '@/components/ResetPassword.vue';

export default {
	name: 'OnboardingFormationTopic',
	components: { ResetPassword },
	data() {
		return {
			step: '',
			formationId: '',
			userId: null,
		};
	},
	async mounted() {
		// Get formation data
		this.formationId = this.$route.params.formationId;
		const formation = await this.$store.dispatch('formation/getFormationData', { idFormation: this.formationId });

		// Get user data
		const user = await this.$store.dispatch('accountManager/getUserWihoutFormation', { idUser: formation.user_id });
		this.userId = user._id;
		if (user.has_changed_password === true) return this.redirectToTopicDashboard();
		if (user.has_changed_password != true) {
			this.step = 'ResetPassword';
			return;
		}
	},
	methods: {
		nextStep(value) {
			this.step = value;
			if (value === 'Redirect to topic dashboard') this.redirectToTopicDashboard();
		},
		redirectToTopicDashboard() {
			this.$router.push({
				name: 'Topic Dashboard Index',
				params: { topicSlug: this.$route.params.topicSlug, formationId: this.$route.params.formationId },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	height: 100%;
}
</style>
